import * as React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import InvoiceSelect from "./InvoiceSelect";
import { ISelect } from "../index";
import InvoicePayButton from "/components/main/AccordionBlock/InvoicePayButton";
import { ReactNode } from "react";
import IconWalletConnect from "./icon-wallet-connect.svg";
import cn from "classnames";

interface IInvoiceFooterProps {
  selects: ISelect[];
  isPayBtnDisabled: boolean;
  handlePay?: () => void;
  isLoadingLink: boolean;
  isShowWalletConnect?: boolean;
  darkTheme?: boolean;
  btnText?: string;
}

interface IConditionButtons {
  title: string;
  icon: ReactNode;
  isDisabled?: boolean;
}

const conditionButtons: IConditionButtons[] = [
  {
    title: "Wallet Connect",
    icon: <IconWalletConnect />,
    isDisabled: true,
  },
];

const InvoiceFooter: React.FC<IInvoiceFooterProps> = ({
  selects,
  isPayBtnDisabled,
  handlePay,
  isLoadingLink,
  isShowWalletConnect = true,
  darkTheme = false,
  btnText
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={cn(styles.payForm__footer, { [styles.payForm__footer_dark]: darkTheme })}>
      <div className={styles.selects__wrapper}>
        {selects?.map((select) => (
          <InvoiceSelect darkTheme={darkTheme} key={select.id} isDisabled={!!select.isDisabled}>
            <p>{t(`accordion.${select.title}`, select.title)}</p>
          </InvoiceSelect>
        ))}
      </div>

      <InvoicePayButton
        darkTheme={darkTheme}
        handlePay={handlePay}
        isLoading={isLoadingLink}
        isDisabled={isPayBtnDisabled}
        btnText={btnText}
      />

      {isShowWalletConnect ? (
        <>
          <div className={styles.condition__text_wrapper}>
            <div className={styles.condition__line} />

            <p>{t("accordion.OR")}</p>

            <div className={styles.condition__line} />
          </div>

          <div className={styles.condition__buttons_wrapper}>
            {conditionButtons?.map((item) => (
              <button
                key={item.title}
                className={cn(styles.condition__button, {
                  [styles.condition__button_disabled]: item.isDisabled,
                })}
              >
                {item.icon}
                <p>{item.title}</p>
              </button>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default InvoiceFooter;
