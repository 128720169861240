import * as React from "react";
import styles from "./InvoiceSelect.module.scss";
import { useTranslation } from "react-i18next";
import IconArrowDown from "public/img/icon-arrow-down.svg";
import cn from "classnames";

interface InvoiceSelectProps {
  children: React.ReactNode;
  isDisabled?: boolean;
  darkTheme?: boolean;
}

const InvoiceSelect: React.FC<InvoiceSelectProps> = ({ children, isDisabled, darkTheme }) => {
  return (
    <div
      className={cn(styles.select, {
        [styles.select_disable]: isDisabled,
        [styles.select_dark]: darkTheme,
      })}
    >
      {children}
      <IconArrowDown />
    </div>
  );
};

export default InvoiceSelect;
