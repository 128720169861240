import React, { ReactNode, useState } from "react";
import styles from "./SupportCoinsSlider.module.scss";
import InfiniteScroll from "components/shared/InfiniteScroll/InfiniteScroll";
import Link from "next/link";
import CPT from "public/img/coins/cpt.svg";
import cn from "classnames";
import { api } from "pages/_app";
import { useQuery } from "@tanstack/react-query";

interface ICoin {
  icon: string;
  bgColor: string;
  bgConstColor?: string;
  url: string;
  svgComponent?: ReactNode;
  currency_code: string;
}

export const coinList: ICoin[] = [
  {
    icon: "bitcoin_1.svg",
    bgColor: "#F7931A",
    url: "accept-btc-as-a-business-how-to-get-paid-in-bitcoin-on-your-website",
    currency_code: "BTC",
  },
  {
    icon: "dash.svg",
    bgColor: "#008CE7",
    url: "accept-dash-as-a-business-how-to-get-paid-in-dash-on-your-website",
    currency_code: "DASH",
  },
  {
    icon: "DOGE.svg",
    bgColor: "#000",
    bgConstColor: "var(--primary)",
    url: "accept-doge-as-a-business-how-to-get-paid-in-doge-on-your-website",
    currency_code: "DOGE",
  },
  {
    icon: "tether.svg",
    bgColor: "#50AF95",
    url: "accept-usdt-as-a-business-how-to-get-paid-in-tether-on-your-website",
    currency_code: "USDT",
  },
  {
    icon: "polygon.svg",
    bgColor: "#8247E5",
    url: "accept-matic-as-a-business-how-to-get-paid-in-polygon-on-your-website",
    currency_code: "MATIC",
  },
  {
    icon: "binance.svg",
    bgColor: "#F0B90B",
    url: "accept-bnb-as-a-business-how-to-get-paid-in-binance-coin-on-your-website",
    currency_code: "BNB",
  },
  {
    icon: "xmr.svg",
    bgColor: "#000",
    bgConstColor: "var(--primary)",
    url: "",
    currency_code: "XMR",
  },
  {
    icon: "solano.svg",
    bgColor: "var(--primary)",
    url: "",
    currency_code: "SOL",
  },
  {
    icon: "bitcoin_2.svg",
    bgColor: "#4BCF51",
    url: "",
    currency_code: "BCH",
  },
  {
    icon: "etherium.svg",
    bgColor: "#627EEA",
    url: "accept-eth-as-a-business-how-to-get-paid-in-ether-on-your-website",
    currency_code: "ETH",
  },
  {
    icon: "litecoin.svg",
    bgColor: "#CBC6C6",
    url: "accept-ltc-as-a-business-how-to-get-paid-in-litecoin-on-your-website",
    currency_code: "LTC",
  },
  {
    icon: "cgpt.svg",
    bgColor: "#000",
    bgConstColor: "var(--primary)",
    url: "",
    currency_code: "CGPT",
  },
  {
    icon: "ton.svg",
    bgColor: "#0088CC",
    url: "accept-ton-as-a-business-how-to-get-paid-in-toncoin-on-your-website",
    currency_code: "TON",
  },
  {
    icon: "trx.svg",
    bgColor: "#E50915",
    url: "accept-trx-as-a-business-how-to-get-paid-in-tron-on-your-website",
    currency_code: "TRX",
  },
  {
    icon: "cpt.svg",
    bgColor: "#000000",
    bgConstColor: "var(--primary)",
    url: "",
    svgComponent: <CPT />,
    currency_code: "CRMS",
  },
  {
    icon: "dai.svg",
    bgColor: "#F5AC37",
    url: "accept-dai-as-a-business-how-to-get-paid-in-dai-on-your-website",
    currency_code: "DAI",
  },
  {
    icon: "usdc.svg",
    bgColor: "#2775CA",
    url: "accept-usdc-as-a-business-how-to-get-paid-in-usd-coin-on-your-website",
    currency_code: "USDC",
  },
];

const SupportCoinsSlider: React.FC = () => {
  const [currencyList, setCurrencyList] = useState<ICoin[]>([]);

  useQuery(["getCurrencyList"], () => api.get("v1/config/withdraws"), {
    onSuccess: (data) => {
      const dataCopy = data?.data?.result?.length ? [...data.data.result] : [];

      const parsedCoinList = coinList?.map((item) => {
        const index = dataCopy?.findIndex(
          (searched) => item.currency_code === searched.currency_code,
        );

        if (index !== -1) {
          const result = {
            ...item,
            url: dataCopy[index]?.post_link ?? "",
          };

          dataCopy.splice(index, 1);

          return result;
        }

        return item;
      });

      setCurrencyList(parsedCoinList);
    },
  });

  return (
    <div className={styles.media}>
      <div className={styles.infinite_container}>
        <InfiniteScroll
          items={currencyList}
          renderItem={(item) => (
            <Link
              key={item.currency_code}
              target="_blank"
              href={"blog/" + item?.url}
              className={cn(styles.infinite_item, {
                [styles.infinite_item_disable]: !item?.url?.length,
              })}
            >
              <div
                style={{
                  backgroundColor: item?.bgConstColor ? `${item?.bgConstColor}` : `${item.bgColor}`,
                }}
                className={styles.coin__item}
              >
                {item?.svgComponent ? (
                  <div className={styles.coin__item__svg}>{item?.svgComponent}</div>
                ) : (
                  <img src={`/img/coins/${item.icon}`} alt={item.coin} />
                )}
              </div>
            </Link>
          )}
        />
      </div>
    </div>
  );
};

export default SupportCoinsSlider;
