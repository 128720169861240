import * as React from "react";
import styles from "./index.module.scss";
import WalletHeader from "components/main/AccordionBlock/WalletHeader";
import IconSpinner from "/public/img/icon-spinner.svg";
import BalanceItem from "components/main/AccordionBlock/BalanceItem";
import { useTranslation } from "react-i18next";
import cn from "classnames";

export interface IInvoiceHeader {
  title: string;
  subTitle: string;
  isHideClipboardBtn?: boolean;
  isShowDescription?: boolean;
  children?: React.ReactNode;
  darkTheme?: boolean;
  cashback?: number;
}

const InvoiceHeader: React.FC<IInvoiceHeader> = ({
  title,
  subTitle,
  isHideClipboardBtn,
  isShowDescription = true,
  children,
  darkTheme = false,
  cashback,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={cn(styles.payForm__container, { [styles.payForm__container_dark]: darkTheme })}>
      <div className={styles.header__wrapper}>
        <WalletHeader darkTheme={darkTheme} cashback={cashback}>
          {children}
        </WalletHeader>

        <BalanceItem
          title={title}
          props={subTitle}
          isHideClipboardBtn={isHideClipboardBtn}
          isHideInfo={isShowDescription}
          info="You pay network fee"
          darkTheme={darkTheme}
        />

        <div className={styles.wallet__timer_wrapper}>
          <IconSpinner />

          <div className={styles.wallet__timer__text_wrapper}>
            <p className={styles.wallet__timer__text}>{t("accordion.Expiration time")}</p>
            <p className={styles.wallet__timer}>02:34:54</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceHeader;
