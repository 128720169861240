import * as React from "react";
import styles from "./WalletHeader.module.scss";
import IconLogo from "/public/img/logo-cryptomus.svg";
import IconGlobe from "/public/img/icon-globe.svg";
import IconCube from "/public/img/icon-cryptomus-cube.svg";
import IconCubeMobile from "/public/img/icon-cryptomus-cube_mobile.svg";
import IconTooltip from "/public/img/icon-tooltip.svg";
import cn from "classnames";

interface IWalletHeaderProps {
  cashback?: number;
  children?: React.ReactNode;
  darkTheme?: boolean;
}

const WalletHeader: React.FC<IWalletHeaderProps> = ({ cashback, children, darkTheme = false }) => {
  return (
    <div className={cn(styles.wallet_header, { [styles.wallet_header_dark]: darkTheme })}>
      {children ? (
        children
      ) : (
        <>
          <div className={styles.logo_mobile}>
            <IconCubeMobile className={styles.logo} />
          </div>
          <div className={styles.logo_desktop}>
            <IconLogo className={styles.logo} style={{ height: "40px" }} />
          </div>
        </>
      )}

      <div className={styles.right__block}>
        {cashback ? (
          <div className={styles.coin__plus}>
            <div className={styles.cube__btn}>
              <IconCube />
            </div>
            + {cashback}
            <IconTooltip />
          </div>
        ) : null}

        <div className={styles.globe__btn}>
          <IconGlobe />
        </div>
      </div>
    </div>
  );
};

export default WalletHeader;
