import * as React from "react";
import styles from "./BalanceItem.module.scss";
import IconInfo from "/public/img/icon-info.svg";
import IconCopy from "/public/img/icon-copy.svg";
import { useTranslation } from "react-i18next";
import cn from "classnames";

interface IBalanceItem {
  title: string;
  props: string;
  info: string;
  isHideClipboardBtn?: boolean;
  isHideInfo: boolean;
  darkTheme?: boolean;
}

const BalanceItem: React.FC<IBalanceItem> = ({
  title,
  props,
  info,
  isHideClipboardBtn,
  isHideInfo = false,
  darkTheme = false,
}) => {
  const { t } = useTranslation("common");

  return (
    <div
      className={cn(styles.wallet__balance__wrapper, {
        [styles.wallet__balance__wrapper_dark]: darkTheme,
      })}
    >
      <div className={styles.wallet__top}>
        <div className={styles.wallet__balance}>{t(`accordion.${title}`, title)}</div>

        {!isHideClipboardBtn ? (
          <button className={styles.copy__btn} aria-label={"copy button"}>
            <IconCopy />
          </button>
        ) : null}
      </div>

      <p className={styles.wallet__props}>{t(`accordion.${props}`, props)}</p>
      {!isHideInfo ? (
        <div className={styles.wallet__info}>
          <IconInfo />
          <p>{t(`accordion.${info}`)}</p>
        </div>
      ) : null}
    </div>
  );
};

export default BalanceItem;
