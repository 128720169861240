import React, { ReactNode } from "react";
import styles from "./InfiniteScroll.module.scss";

interface IInfiniteScrollProps<T> {
  items: T[];
  renderItem: (item: T) => ReactNode;
}

const InfiniteScroll = <T,>({ items, renderItem }: IInfiniteScrollProps<T>) => {
  return (
    <div className={styles.scroll_parent}>
      <div className={styles.scroll_element}>{items.map((item) => renderItem(item))}</div>
      <div className={styles.scroll_element}>{items.map((item) => renderItem(item))}</div>
    </div>
  );
};

export default InfiniteScroll;
